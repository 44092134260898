<template>
  <div class="internal-communication-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="导入" permission="import" @click="importExcel"></v-button>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="登录名" v-model="searchParams.loginName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select label="角色类别" v-model="searchParams.userType" :options="roleTypeOps"></v-select>
        <v-select2 label="角色归属" v-model="searchParams.orgId" :disabled="!searchParams.userType" v-bind="orgParams" :subjoin="{ roleType: searchParams.userType }"></v-select2>
        <v-select label="来源" v-model="searchParams.source" :options="sourceOps"></v-select>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="remove(scope.row)" v-if="scope.row.source == 1"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getInternalListURL, exportInURL, getOrgURL, deleteURL } from './api'
import { statusMap, setStatusOps, sourceMap, setSourceOps, roleTypeMap, setRoleTypeOps } from './map'

export default {
  name: 'InternalCommunicationList',
  data () {
    return {
      searchUrl: getInternalListURL,
      exportUrl: exportInURL,
      statusOps: setStatusOps(1),
      roleTypeOps: setRoleTypeOps(1),
      sourceOps: setSourceOps(1),
      orgParams: {
        searchUrl: getOrgURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      searchParams: {
        userName: '',
        status: undefined,
        orgId: '',
        fromtDate: '',
        toDate: ''
      },
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'loginName',
          label: '登录名',
          formatter: row => row.loginName || '/'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'roleName',
          label: '角色名称',
          formatter: row => row.roleName || '/'
        },
        {
          prop: 'roleTypeName',
          label: '角色类别',
          formatter: row => roleTypeMap[row.userType]
        },
        {
          prop: 'orgName',
          label: '角色归属'
        },
        {
          prop: 'sourceText',
          label: '来源',
          formatter: row => sourceMap[row.source]
        },
        {
          prop: 'status',
          label: '状态',
          formatter: row => statusMap[row.status]
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        }
      ]
    }
  },
  created () {
    const { userType } = this.$store.state.userInfo
    if (userType === '102') {
      console.log(this.roleTypeOps)
      this.roleTypeOps.splice(1, 1)
    }
    if (userType === '101') {
      this.roleTypeOps.splice(1, 2)
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'InternalCommunicationForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'InternalCommunicationForm',
        query: {
          id: row.id
        }
      })
    },
    importExcel () {
      this.$router.push({
        name: 'InternalCommunicationImport'
      })
    },
    async remove (row) {
      let isConfirm = await this.$confirm('是否删除？')
      if (isConfirm) {
        let { status } = await this.$axios.post(`${deleteURL}?maillistId=${row.id}`)
        if (status - 0 === 100) {
          this.$refs.list.searchData()
        }
      }
    }
  }
}
</script>
