import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '显示',
    value: 1
  },
  {
    text: '隐藏',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 来源
const source = [
  {
    text: '账号管理',
    value: 0
  },
  {
    text: '导入',
    value: 1
  }
]

const {
  map: sourceMap,
  setOps: setSourceOps
} = mapHelper.setMap(source)

// 角色类型

const roleType = [
  {
    text: '平台',
    value: 100
  },
  {
    text: '公司',
    value: 102
  },
  {
    text: '项目',
    value: 101
  }
]

const {
  map: roleTypeMap,
  setOps: setRoleTypeOps
} = mapHelper.setMap(roleType)

export {
  sourceMap,
  setSourceOps,
  statusMap,
  setStatusOps,
  roleTypeMap,
  setRoleTypeOps
}
